import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import { trackPhoneStatus } from '../../utils/trackProfileInteraction'

interface IModalPhoneConfirmationProps {
	open: boolean
	handlePhoneModal: (value: boolean) => void
	uid: string,
	type:string,
	profile: any 
	vipId: string
}

function ModalPhoneConfirmation({ open, handlePhoneModal, uid, profile, type,vipId }: IModalPhoneConfirmationProps) {
	const { workName, mobile }= profile
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (open) {
			setShowModal(true)
		}
	}, [open])

	const handleYesAnswer = () => {
		setLoading(true)
		trackPhoneStatus({
			uid,
			workName,
			vipId,
			mobileNumber: mobile,
			type,
			result: 'SUCCESS',
		})
		setShowModal(false)
		setLoading(false)
	}

	const handleNoAnswer = () => {
		setLoading(true)
		trackPhoneStatus({
			uid,
			workName,
			vipId,
			mobileNumber: mobile,
			type,
			result: 'NO_ANSWER',
		})
		setShowModal(false)
		setLoading(false)
	}

	const handleOffAnswer = () => {
		setLoading(true)
		trackPhoneStatus({
			uid,
			workName,
			vipId,
			mobileNumber: mobile,
			type,
			result: 'PHONE_OFF',
		})
		setShowModal(false)
		setLoading(false)
	}

	const handleCancel = () => {
		setShowModal(false)
		handlePhoneModal(false)
	}

	return (
		<Modal
			open={showModal}
			onCancel={handleCancel}
			footer={[
				<Button
					key="phone-yes-answer"
					type="primary"
					loading={loading}
					onClick={handleYesAnswer}
					style={{ backgroundColor: '#FFFFFF', borderColor: '#EE1E3A', color: '#EE1E3A' }}
				>
					Yes
				</Button>,
				<Button
					key="phone-no-answer"
					loading={loading}
					onClick={handleNoAnswer}
					style={{ backgroundColor: '#EE1E3A', borderColor: '#EE1E3A' }}
				>
					Kept ringing
				</Button>,
				<Button
					key="phone-no-answer"
					loading={loading}
					onClick={handleOffAnswer}
					style={{ backgroundColor: '#EE1E3A', borderColor: '#EE1E3A' }}
				>
					Phone was off
				</Button>,
				<Button key="phone-back" onClick={handleCancel}>
					I didn&apos;t call
				</Button>,
			]}
		>
			<p>Did {workName} answer your {type}?</p>
		</Modal>
	)
}

export default ModalPhoneConfirmation
